import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
export const CrossIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
    aria-labelledby={titleId}
    width="100%"
    height="100%"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="m4 4 5 5M9 4 4 9"
    />
  </svg>
);
